<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('externalTraining.routine') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col sm="12">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset">
                <b-row>
                    <b-col lg="6" sm="6">
                        <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('elearning_config.fiscal_year')}}
                            </template>
                            <b-form-select
                                plain
                                v-model="search.fiscal_year_id"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                                <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col>
                      <b-button type="submit" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </b-col>
                </b-row>
              </b-form>
            </ValidationObserver>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
        <b-col md="12">
            <iq-card>
                <template v-slot:headerTitle>
                    <h4 class="card-title">{{ $t('externalTraining.routine') }}</h4>
                </template>
                <template v-slot:body>
                    <b-overlay :show="loadingState">
                        <b-row>
                            <b-col md="12" class="table-responsive">
                                <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                    <template v-slot:cell(index)="data">
                                    {{ $n(data.index + pagination.slOffset) }}
                                    </template>
                                    <template v-slot:cell(type_name)="data">
                                    <span class="capitalize">{{ data.item.type_name }}</span>
                                    </template>
                                    <template v-slot:cell(status)="data">
                                        <span class="badge badge-info" v-if="data.item.flag_status == 1">{{$t('globalTrans.draft')}}</span>
                                        <span class="badge badge-success" v-else-if="data.item.flag_status == 2">{{$t('globalTrans.approved')}}</span>
                                    </template>
                                    <template v-slot:cell(training_date)="data">
                                        <span class="capitalize">{{ data.item.training_date | dateFormat }}</span>
                                    </template>
                                    <template v-slot:cell(start_time)="data">
                                        <span class="capitalize">{{ data.item.start_time | time12FormateTraining }}</span>
                                    </template>
                                    <template v-slot:cell(end_time)="data">
                                        <span class="capitalize">{{ data.item.end_time | time12FormateTraining }}</span>
                                    </template>
                                    <template v-slot:cell(action)="data">
                                        <router-link class="action-btn view" variant=" iq-bg-success mr-1" :title="$t('globalTrans.details')" :to="`routine?circular_memo_no=${data.item.circular_memo_no}&batch_no=${data.item.batch_no}`"><i class="fas fa-eye"></i></router-link>
                                    </template>
                                </b-table>
                                <!-- <b-pagination
                                    align="center"
                                    v-model="pagination.currentPage"
                                    :per-page="pagination.perPage"
                                    :total-rows="pagination.totalRows"
                                    @input="searchData"
                                    /> -->
                            </b-col>
                        </b-row>
                    </b-overlay>
                </template>
            </iq-card>
        </b-col>
    </b-row>
  </b-container>
</template>
<style>
.tables-data th, .tables-data td {
  padding: 0px 0px 0px 10px !important;
}
.tables-data {
  margin-bottom: 0px !important
}
</style>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { externalUserScheduleDataList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'

export default {
    mixins: [ModalBaseMasterList],
    components: {
    },
    data () {
        return {
          search: {
            fiscal_year_id: 0,
            registration_for: this.$store.state.ExternalUserIrrigation.panelObj.trainingType
          },
          loadingSearch: false,
          routines: [],
          committee: [],
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          allData: []
        }
    },
    computed: {
      ...mapGetters({
        authUser: 'Auth/authUser'
      }),
        fiscalYearList: function () {
            return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0)
        },
        allBatchList () {
            return [
                { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
                { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
                { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
                { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
                { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' }
            ]
        },
      orgList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
              { label: this.$t('elearning_config.fiscal_year'), class: 'text-left' },
              { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-left' },
              { label: this.$t('elearning_iabm.batch_no'), class: 'text-left' },
              { label: this.$t('elearning_config.training_type'), class: 'text-left' },
              { label: this.$t('elearning_config.training_category'), class: 'text-left' },
              { label: this.$t('elearning_config.training_title'), class: 'text-left' },
              { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
                { key: 'index' },
                { key: 'fiscal_year_bn' },
                { key: 'circular_memo_no' },
                { key: 'batch_name_bn' },
                { key: 'training_type_bn' },
                { key: 'training_category_bn' },
                { key: 'training_title_bn' },
                { key: 'action' }
              ]
          } else {
              keys = [
                { key: 'index' },
                { key: 'fiscal_year' },
                { key: 'circular_memo_no' },
                { key: 'batch_name' },
                { key: 'training_type' },
                { key: 'training_category' },
                { key: 'training_title' },
                { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
    },
    async created () {
        this.search.fiscal_year_id = this.getCurrentFiscalYearId(this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0))
        this.searchData()
    },
    mounted () {
        core.index()
    },
    watch: {
    },
    methods: {
        getCurrentFiscalYearId (fiscalYearList) {
            if (fiscalYearList.length === 0) {
              return
            }
            const currentDate = new Date()
            const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
            /** Define the year position  */
            const yearPosition = month < 7 ? 5 : 0
            const yearStr = `${year}`
            let currentFiscalYearId = 0
            fiscalYearList.forEach(element => {
              if (element.text_en.indexOf(yearStr) === yearPosition) {
                currentFiscalYearId = element.value
              }
            })
            return currentFiscalYearId
        },
        searchData () {
            this.loadData()
        },
        loadData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(trainingElearningServiceBaseUrl, externalUserScheduleDataList, this.search).then(response => {
                if (response.success) {
                    // this.routines = response.data
                    this.$store.dispatch('setList', this.getCustomDataList(response.data))
                    // this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getCustomDataList (data) {
            const listData = data.map(item => {
            const orgObj = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
                orgData.org = orgObj.text_en
                orgData.org_bn = orgObj.text_bn
            } else {
                orgData.org = ''
                orgData.org_bn = ''
            }
            const trainingTitleObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
            const trainingTitleData = {}
            if (typeof trainingTitleObj !== 'undefined') {
                trainingTitleData.training_title = trainingTitleObj.text_en
                trainingTitleData.training_title_bn = trainingTitleObj.text_bn
            } else {
                trainingTitleData.training_title = ''
                trainingTitleData.training_title_bn = ''
            }
            const trainingTypeObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
            const trainingTypeData = {}
            if (typeof trainingTypeObj !== 'undefined') {
                trainingTypeData.training_type = trainingTypeObj.text_en
                trainingTypeData.training_type_bn = trainingTypeObj.text_bn
            } else {
                trainingTypeData.training_type = ''
                trainingTypeData.training_type_bn = ''
            }
            const trainingCategoryObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
            const trainingCategoryData = {}
            if (typeof trainingCategoryObj !== 'undefined') {
                trainingCategoryData.training_category = trainingCategoryObj.text_en
                trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
            } else {
                trainingCategoryData.training_category = ''
                trainingCategoryData.training_category_bn = ''
            }
            const batchObj = this.allBatchList.find(batch => batch.value === parseInt(item.batch_no))
            const batchData = {}
            if (typeof batchObj !== 'undefined') {
                batchData.batch_name = batchObj.text_en
                batchData.batch_name_bn = batchObj.text_bn
            } else {
                batchData.batch_name = ''
                batchData.batch_name_bn = ''
            }
            const fiscalYearObj = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
            const fiscalData = {}
            if (typeof fiscalYearObj !== 'undefined') {
                fiscalData.fiscal_year = fiscalYearObj.text_en
                fiscalData.fiscal_year_bn = fiscalYearObj.text_bn
            } else {
                fiscalData.fiscal_year = ''
                fiscalData.fiscal_year_bn = ''
            }
            return Object.assign({}, item, orgData, batchData, trainingCategoryData, trainingTypeData, fiscalData, trainingTitleData)
            })
            return listData
        }
    }
}
</script>
